<template>
  <div class="bg-white p-4 h-full text-black">
    <h2 class="font-bold fs-24 mb-4">{{ $t("Thiết lập Dịch vụ") }}</h2>
    <p
      v-if="!$route.query.type || ($route.query.type && form.name && imageUrl)"
    >
      Ảnh dịch vụ
    </p>
    <img
      v-if="imageUrl && $route.query.type"
      :src="imageUrl"
      class="avatar rounded mb-3"
    />
    <div class="flex items-center gap-4" v-if="!$route.query.type">
      <el-upload
        class="mb-4"
        action="#"
        :show-file-list="false"
        :auto-upload="false"
        :on-change="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar rounded" />
        <i v-else class="el-icon-plus avatar-uploader-icon icon-plus"></i>
      </el-upload>
      <div class="flex flex-col gap-3">
        <el-upload
          action="#"
          :show-file-list="false"
          :on-change="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <div
            class="flex items-center gap-2"
            v-if="imageUrl"
            @click="handleAvatarSuccess"
          >
            <img src="/images/icon-edit.svg" alt />
            <span class="cursor-pointer btn-edit-image">Chỉnh sửa</span>
          </div>
        </el-upload>

        <div
          class="flex items-center gap-2"
          v-if="imageUrl"
          @click="imageUrl = ''"
        >
          <img src="/images/icon-delete.svg" alt />
          <span class="cursor-pointer btn-delete-image">Xoá</span>
        </div>
      </div>
    </div>
    <el-form :model="form" :rules="rules" ref="createServiceCatalog">
      <div
        class="flex gap-2 flex-col md:flex-row items-center"
        v-if="!$route.query.type"
      >
        <el-form-item class="flex-1" prop="name">
          <label class="required">{{ $t("Tên dịch vụ") }}</label>
          <el-input class v-model="form.name"></el-input>
        </el-form-item>
      </div>
      <div v-else class="flex gap-2 flex-col md:flex-row items-center">
        <el-form-item class="flex-1" prop="name">
          <label class="required">{{ $t("Chọn dịch vụ") }}</label>
          <el-select
            class="w-full"
            clearable
            filterable
            v-model="form.name"
            placeholder="Chọn danh mục dịch vụ"
            @change="handleChooseService"
          >
            <el-option
              v-for="(item, index) in services"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex flex-col md:flex-row gap-4 items-center">
        <el-form-item class="flex-1" prop="services_code">
          <label class>{{ $t("Mã dịch vụ") }}</label>
          <el-input class v-model="form.services_code"></el-input>
        </el-form-item>
        <el-form-item class="flex-1" prop="price">
          <label class="required block">{{ $t("Giá dịch vụ") }}</label>
          <el-input
            :disabled="isDisabled"
            class="cs-input cs-price"
            v-model="form.price"
            @input="handleChangePrice"
          >
            <template slot="append">VND</template>
          </el-input>
        </el-form-item>
      </div>
      <el-form-item prop="description">
        <label class>{{ $t("Mô tả") }}</label>
        <el-input
          :disabled="isDisabled"
          type="textarea"
          :autosize="{ minRows: 4 }"
          v-model="form.description"
        ></el-input>
      </el-form-item>
      <div class="flex gap-4 flex-col md:flex-row items-center">
        <!-- <el-form-item class="flex-1" prop="type">
          <el-checkbox
            v-model="form.is_internal_service"
            label="Đây là dịch vụ do tổ chức tự cung cấp"
            name="type"
          ></el-checkbox>
        </el-form-item>-->
        <el-form-item class="flex-1" prop="type">
          <el-checkbox
            v-model="form.is_accept_outbound_request"
            label="Cho phép các tổ chức bên ngoài đặt dịch vụ"
            name="type"
            :disabled="isDisabled"
          ></el-checkbox>
        </el-form-item>
      </div>
      <div class="flex flex-col md:flex-row gap-4 items-center">
        <!-- <el-form-item class="flex-1" prop="group_cate">
          <label class="block">{{ $t("Nhóm Danh mục Dịch vụ") }}</label>

          <el-select
            class="w-full"
            clearable
            v-model="form.group_cate"
            placeholder="Chọn nhóm danh mục dịch vụ"
          >
            <el-option
              v-for="(groupItem, index) in groupCates"
              :key="index"
              :label="groupItem.name"
              :value="groupItem.id"
            ></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item class="flex-1" prop="services_category_id">
          <label class="block">{{ $t("Danh mục Dịch vụ") }}</label>

          <el-select
            class="w-full"
            clearable
            filterable
            v-model="form.services_category_id"
            placeholder="Chọn danh mục dịch vụ"
            :disabled="isDisabled"
          >
            <el-option
              v-for="(serviceCateItem, index) in serviceCates"
              :key="index"
              :label="serviceCateItem.name"
              :value="serviceCateItem.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex flex-col md:flex-row gap-4 items-center">
        <!-- <el-form-item class="flex-1" prop="provider">
          <label class="block">{{ $t("Đơn vị cung cấp") }}</label>

          <el-select
            class="w-full"
            clearable
            v-model="form.service_provider_id"
            placeholder="Chọn đơn vị cung cấp"
          >
            <el-option
              v-for="(provider, index) in serviceProviders"
              :key="index"
              :label="provider.name"
              :value="provider.id"
            ></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item class="flex-1" prop="service_type">
          <label class="block">{{ $t("Loại Dịch vụ") }}</label>

          <el-select
            class="w-full"
            clearable
            filterable
            v-model="form.service_type"
            placeholder="Chọn loại dịch vụ"
            :disabled="isDisabled"
          >
            <el-option
              v-for="(typeItem, index) in SERVICE_TYPE"
              :key="index"
              :label="typeItem.label"
              :value="typeItem.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="flex flex-col md:flex-row gap-4 items-center">
        <el-form-item class="flex-1" prop="decision_no">
          <label class>{{ $t("Số quyết định") }}</label>
          <el-input
            v-model="form.decision_no"
            :disabled="isDisabled"
          ></el-input>
        </el-form-item>
        <el-form-item class="flex-1" prop="publish_date">
          <label class>{{ $t("Ngày quyết định") }}</label>
          <el-date-picker
            v-model="form.publish_date"
            :disabled="isDisabled"
            type="date"
          ></el-date-picker>
        </el-form-item>
      </div>
      <div class="element mb-3">
        <p class="mb-2 font-semibold">Chỉ số</p>
        <el-collapse v-model="activeElement" accordion>
          <el-collapse-item
            :title="`Chỉ số ${index + 1}`"
            :name="item.index"
            v-for="(item, index) in listElement"
            :key="index"
          >
            <div class="grid grid-cols-3 gap-3">
              <el-form-item class="flex-1">
                <label class>{{ $t("Mã chỉ số") }}</label>
                <el-input v-model="item.service_element_code"></el-input>
              </el-form-item>
              <el-form-item class="flex-1">
                <label class>{{ $t("Tên chỉ số") }}</label>
                <el-input :disabled="isDisabled" v-model="item.name"></el-input>
              </el-form-item>
              <el-form-item class="flex-1">
                <label class>{{ $t("Giá") }}</label>
                <el-input
                  :disabled="isDisabled"
                  v-model="item.price"
                  @input="formatNumber(item, 'price')"
                >
                  <template slot="append">VND</template>
                </el-input>
              </el-form-item>
            </div>

            <div>
              <p class="mb-0 text-base">Khoảng tham chiếu</p>
              <div class="grid grid-cols-3 gap-3">
                <el-form-item>
                  <label class>{{ $t("Dưới") }}</label>
                  <el-input
                    :disabled="isDisabled"
                    v-model="item.min_value"
                  ></el-input>
                </el-form-item>
                <el-form-item class="flex-1">
                  <label class>{{ $t("Trên") }}</label>
                  <el-input
                    :disabled="isDisabled"
                    v-model="item.max_value"
                  ></el-input>
                </el-form-item>
                <el-form-item class="flex-1">
                  <label class>{{ $t("Đơn vị") }}</label>
                  <el-input
                    :disabled="isDisabled"
                    v-model="item.element_unit"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <el-form-item class="flex-1">
              <label class>{{ $t("Mô tả") }}</label>
              <el-input
                :disabled="isDisabled"
                rows="3"
                type="textarea"
                v-model="item.description"
              ></el-input>
            </el-form-item>
            <div class="flex items-center mb-2">
              <el-button
                plain
                class
                @click="handleAddNewElement"
                v-if="!isDisabled"
                >Thêm chỉ số</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-delete"
                class="btn-delete"
                v-if="listElement.length !== 1 && !$route.query.type"
                @click="handleRemoveElement(index)"
              ></el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <el-form-item class="flex justify-end">
        <el-button @click="resetForm()">{{ $t("Huỷ") }}</el-button>
        <el-button
          v-if="$route.params.id"
          type="primary"
          @click="submitForm()"
          :loading="isLoading"
          >{{ $t("Cập nhật") }}</el-button
        >
        <el-button
          v-else
          type="primary"
          @click="submitForm()"
          :loading="isLoading"
          >{{ $t("Tạo mới") }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { SERVICE_TYPE } from '../../utils/constants'
// import { indexOf } from 'lodash'
export default {
  name: 'CreateServiceCatalog',
  setup () {
    return {
      appUtils
    }
  },
  data () {
    return {
      isLoading: false,
      form: {
        name: '',
        services_code: '',
        description: '',
        // group_cate: null,
        services_category_id: null,
        price: '',
        // is_internal_service: true,
        is_accept_outbound_request: false,
        service_provider_id: null,
        service_type: null,
        decision_no: '',
        publish_date: null,
        supplier_id: null
      },
      activeElement: 0,
      priceFormat: '',
      serviceDetail: {},
      listElement: [
        {
          name: '',
          service_element_code: '',
          description: '',
          price: '',
          min_value: '',
          max_value: '',
          index: 0,
          element_unit: ''
        }
      ],
      services: [],
      rules: {
        name: [
          {
            required: true,
            message: 'Vui lòng nhập tên dịch vụ',
            trigger: 'blur'
          }
        ],
        price: [
          {
            required: true,
            message: 'Vui lòng nhập giá dịch vụ',
            trigger: 'blur'
          }
        ]
      },
      groupCates: [],
      serviceCates: [],
      providers: [],
      serviceTypes: [],
      serviceProviders: [],
      SERVICE_TYPE,
      imageUrl: '',
      fileUrl: '',
      providerInfo: {}
    }
  },
  created () {
    this.getListServiceCategory()
    this.getListServiceProvider()
  },
  computed: {
    isDisabled () {
      return !!this.$route.query.type
    }
  },
  watch: {
    // 'form.is_internal_service': {
    //   async handler (value) {
    //     await this.getListServiceProvider()
    //     if (value === true) {
    //       this.form.service_provider_id = this.serviceProviders[0]?.id || ''
    //     }
    //   }
    // }
  },
  async mounted () {
    if (this.$route.query.type) {
      this.getListServicesPublic()
    }
    if (this.$route.params?.id) {
      await this.getDetailServiceCatalog()
      this.form = {
        name: this.serviceDetail?.name,
        services_code: this.serviceDetail?.services_code,
        description: this.serviceDetail?.description,
        services_category_id: this.serviceDetail?.services_category_id || null,
        price: appUtils.numberFormat(this.serviceDetail?.price),
        is_internal_service: this.serviceDetail?.is_internal_service === 2,
        is_accept_outbound_request:
          this.serviceDetail?.is_accept_outbound_request === 2,
        supplier_id: this.serviceDetail?.product_output?.supplier_id,
        service_provider_id: this.serviceDetail?.service_provider_id || null,
        service_type: this.serviceDetail?.service_type || null,
        decision_no: this.serviceDetail?.decision_no,
        publish_date: this.serviceDetail?.publish_date
          ? new Date(this.serviceDetail?.publish_date)
          : null
      }
      this.listElement = this.serviceDetail?.service_elements?.map((elm) => {
        return {
          ...elm,
          price: appUtils.numberFormat(elm.price),
          index: this.serviceDetail?.service_elements
            ?.map((item) => item.id)
            .indexOf(elm.id)
        }
      })
      if (this.listElement?.length === 0) {
        this.listElement = [
          {
            name: '',
            service_element_code: '',
            description: '',
            price: '',
            min_value: '',
            max_value: '',
            index: 0,
            element_unit: ''
          }
        ]
      }
      this.activeElement = 0
      this.imageUrl = this.serviceDetail?.product_output?.primary_image
    } else {
      console.log('add')
    }
  },
  methods: {
    // formatter (price) {
    //   return `${appUtils.numberFormat(price)}`
    // },
    formatNumber (item, property) {
      item[property] = `${appUtils.numberFormat(item[property])}`
    },
    async handleChooseService (value) {
      await this.getDetailServiceCatalog(value)
      this.imageUrl = this.serviceDetail?.product_output?.primary_image
      this.form = {
        name: this.serviceDetail?.name,
        services_code: this.serviceDetail?.services_code,
        description: this.serviceDetail?.description,
        services_category_id: this.serviceDetail?.services_category_id || null,
        price: appUtils.numberFormat(this.serviceDetail?.price),
        supplier_id: this.serviceDetail?.product_output?.supplier_id,
        is_internal_service: this.serviceDetail?.is_internal_service === 2,
        is_accept_outbound_request:
          this.serviceDetail?.is_accept_outbound_request === 2,
        service_provider_id: this.serviceDetail?.service_provider_id || null,
        service_type: this.serviceDetail?.service_type || null,
        decision_no: this.serviceDetail?.decision_no,
        publish_date: this.serviceDetail?.publish_date
          ? new Date(this.serviceDetail?.publish_date)
          : null
      }
      this.listElement = this.serviceDetail?.service_elements?.map((elm) => {
        return {
          ...elm,
          price: appUtils.numberFormat(elm.price),
          min_value: appUtils.numberFormat(elm.min_value),
          max_value: appUtils.numberFormat(elm.max_value),
          index: this.serviceDetail?.service_elements
            ?.map((item) => item.id)
            .indexOf(elm.id)
        }
      })
      if (!this.listElement?.length) {
        this.listElement = [
          {
            name: '',
            service_element_code: '',
            description: '',
            price: '',
            min_value: '',
            max_value: '',
            index: 0,
            element_unit: ''
          }
        ]
      }
      this.activeElement = 0
      this.imageUrl = this.serviceDetail?.product_output?.primary_image
    },
    submitForm () {
      this.$refs.createServiceCatalog.validate((valid) => {
        if (valid) {
          // alert('submit!')
          if (!this.$route.params.id) this.createServiceCatalog()
          else this.updateServiceCatalog()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.createServiceCatalog.resetFields()
      this.$router.go(-1)
    },

    handleChangePrice (value) {
      const newValue = Number(value?.toString()?.replaceAll(',', ''))
      this.priceFormat = appUtils.numberFormat(newValue)
      this.form.price = this.priceFormat
    },
    async getListServicesPublic (data) {
      try {
        const params = {
          // org_id: this.$globalOrg?.id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc',
          is_accept_outbound_request: 2
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getIndicationService(params)

        const responseData = response.data || {}
        this.services = responseData?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async getListServiceCategory () {
      if (!this.$globalOrg?.id) return
      try {
        const params = {
          org_id: this.$globalOrg?.id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc'
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceCategory(params)
        const responseData = response.data || {}
        this.serviceCates = responseData?.data || []
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    },
    async getListServiceProvider () {
      if (!this.$globalOrg?.id) return
      try {
        const params = {
          // org_id: this.$globalOrg?.id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc'
        }
        // if (this.form.is_internal_service) {
        //   params.org_id = this.$globalOrg?.id
        // }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceProvider(params)
        const responseData = response.data || {}
        this.serviceProviders = responseData?.data || []
        const providerInfo = this.serviceProviders.find(
          (item) => item?.org_id == this.$globalOrg?.id
        )
        this.providerInfo = providerInfo
        // if (this.form.is_internal_service === true) {
        // }
      } catch (error) {
        console.log(error)
      }
    },
    handleAddNewElement () {
      this.listElement.push({
        name: '',
        service_element_code: '',
        description: '',
        price: '',
        min_value: '',
        max_value: '',
        element_unit: '',
        index: this.listElement[this.listElement?.length - 1].index + 1
      })
      this.activeElement = this.listElement[this.listElement?.length - 1].index
    },
    handleRemoveElement (index) {
      this.listElement.splice(index, 1)
    },
    async uploadFile (file) {
      const params = {
        file,
        FolderTarget: `serviceCatalog`,
        IdTarget: window.moment().unix()
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    async createServiceCatalog () {
      try {
        this.isLoading = true
        const params = {
          ...this.form,
          services_code: this.form.services_code,
          publish_date: this.form.publish_date
            ? window.moment(this.form.publish_date).valueOf()
            : undefined,
          price: Number(this.form.price?.toString()?.replaceAll(',', '')),
          org_id: this.$globalOrg.id,
          is_internal_service: 2,
          service_provider_id: this.providerInfo?.id,
          // is_internal_service: this.form.is_internal_service === true ? 2 : 1,
          is_accept_outbound_request:
            this.form.is_accept_outbound_request === true ? 2 : 1,
          service_element_inputs: [
            ...this.listElement.map((elm) => {
              return {
                ...elm,
                price: +elm.price.replaceAll(',', '')
              }
            })
          ].filter((item) => item?.name),
          product_input: {
            primary_image: this.fileUrl
              ? await this.uploadFile(this.fileUrl)
              : '',
            supplier_id: this.providerInfo?.supplier_id
          }
        }
        if (this.$route.query.type) {
          // this.params.name = this.serviceDetail.name
          this.product_input.primary_image = this.imageUrl
        }
        const result = await this.$rf
          .getRequest('DoctorRequest')
          .createServiceCatalog(params)
        if (result.status === 200) {
          this.$router.push({ name: 'ServiceCatalog' })
        }

        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    async updateServiceCatalog () {
      try {
        this.isLoading = true
        const params = {
          // product_input: this.serviceDetail.product_output,
          ...this.form,
          services_code: this.form.services_code,
          name: this.form.name,
          decision_no: this.form.decision_no,
          description: this.form.description,
          service_type: this.form.service_type,
          services_category_id: this.form.services_category_id,
          service_provider_id: this.providerInfo?.id,
          is_internal_service: 2,
          price: Number(this.form.price?.toString()?.replaceAll(',', '')),
          partner_product_id: this.serviceDetail.partner_product_id,
          publish_date: this.form.publish_date
            ? window.moment(this.form.publish_date).valueOf()
            : undefined,
          // org_id: this.$globalOrg.id,
          // is_internal_service: this.form.is_internal_service === true ? 2 : 1,
          is_accept_outbound_request:
            this.form.is_accept_outbound_request === true ? 2 : 1,
          service_elements: [
            ...this.listElement.map((elm) => {
              return {
                ...elm,
                price: +elm.price.replaceAll(',', '')
              }
            })
          ],
          product_input: {
            primary_image: this.fileUrl
              ? await this.uploadFile(this.fileUrl)
              : this.imageUrl,
            supplier_id: this.providerInfo?.supplier_id
          }
        }
        const result = await this.$rf
          .getRequest('DoctorRequest')
          .updateServiceCatalog(this.$route.params.id, params)
        if (result.status === 200) {
          this.$router.push({ name: 'ServiceCatalog' })
        }

        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    async getDetailServiceCatalog (value) {
      try {
        const result = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailServiceCatalog(value || this.$route.params.id)
        if (result.status === 200) {
          this.serviceDetail = result.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleAvatarSuccess (file) {
      this.fileUrl = file.raw
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      //
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-input {
    .el-input__inner {
      text-align: right;
      border: 1px solid #dcdfe6 !important;
    }
  }
}

.cs-price {
  .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

::v-deep.element {
  border-radius: 8px;
  padding: 12px;
  background-color: #f6f6f6;
  .el-collapse .el-collapse-item {
    .el-collapse-item__header {
      background-color: #f6f6f6;
    }
    .el-collapse-item__wrap {
      transition: all ease 0.3s;
      background-color: #f6f6f6;
      .el-collapse-item__content {
        padding-bottom: 0;

        .el-form-item__content {
          .el-input .el-input__inner {
            background-color: #fff !important;
            border-radius: 4px !important;
            border: 1px solid #dbdbdb !important;
          }
        }
      }
    }
  }
}

::v-deep.el-button {
  &:focus {
    outline: 1px auto;
    // margin-left: 1px;
    // border: unset;
  }
}
::v-deep.btn-delete {
  height: 38px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-plus {
  width: 160px;
  height: 160px;
  border: 1px dotted #dbdbdb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    scale: 1.4;
  }
}
.avatar {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.btn-delete-image {
  &:hover {
    color: #ea6a6a;
  }
}
.btn-edit-image {
  &:hover {
    color: #20409b;
  }
}
</style>